import React from "react"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { Link } from 'gatsby';
import './traceability.less';
import { appUrl } from '../helpers/helpers'


import ptiLogo from '../images/logo-pti.svg';
import barcodeLg from '../images/barcode-with-logo.png';
import barcodeStacked from '../images/Asset_5barcode-2.svg';
import barcodeColors from '../images/barcode.jpeg';
import bee1 from '../images/Asset_1Honeybee-1.svg';
import bee2 from '../images/Asset_2Honeybee-2.svg';
import checkmarkImg from '../images/Asset_4green-checkmark.svg';
import strawberryImg from '../images/Asset_3strawberry.svg';
import icon from '../images/icon.svg';
import delhaize from '../images/Delhaize.png';
import albertsons from '../images/Albertsons.jpeg';
import albertsons2 from '../images/Albertsons.webp';
import usda from '../images/USDA.png';
import safeway from '../images/Safeway.webp';
import buylowfoods from '../images/Buy-Low-Foods.jpeg';
import kroger from '../images/Kroger.png';
import costco from '../images/Costco.jpeg';
import ahold from '../images/Ahold.jpg';
import walmart from '../images/Walmart.png';
import wholefoods from '../images/Whole-Foods.png';
import pdf from '../pdf/Label_Example.pdf';

const signupUrl = `${appUrl}/signup?redirect=wizardGrower`;
const demoUrl = 'https://calendly.com/produceplanco/founder-meeting-demo';
const ctaUrl = `${appUrl}/orders/new?source=traceability`;

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const TraceabilityPage = () => (
  <Layout headerColor="#efd595">
    <SEO title="Traceability" />
    <div id="home-b" style={{ overflow: 'hidden' }}>
      <div className="home-panel" id="hero-b" style={{ backgroundColor:"#efd595" }}>
        <div className="container">
          <div id="hero-b-left" style={{ position: 'relative', height: '60vh' }}>
            <h2 style={{ position: 'relative' }}>
              <img src={ptiLogo} alt="PTI" style={{ position: 'relative', left: -31, height: 80 }} />
              <br />
              Finally print your Walmart, Kroger and Costco
              <br />
              PTI compliant labels with ease right in the cloud.
            </h2>
            <p>
            Add our inventory management module and meet all your USDA
            & FDA Produce Traceability Initiative (PTI) requirements, including FSMA 204,
            retailer labeling needs, and more!
            </p>
            <div id="cta-buttons">
              <a
                href={demoUrl}
                className="btn btn-green btn-shadowless btn-rounded green"
                target="_blank"
                rel="noopener noreferrer"
              >
                Schedule Demo
              </a>
              <a
                href={ctaUrl}
                className="btn orange btn-shadowless btn-rounded"
                target="_blank"
                rel="noopener noreferrer"
              >
                Take a tour
              </a>
            </div>


            <div id="partner-logos" style={{ position: 'absolute', width: '100%', left: 0, bottom: -7 }}>
              <Carousel 
                responsive={responsive}
                showDots={false}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                removeArrowOnDeviceType={["all"]}
                deviceType="all"
              >
                <img src={delhaize} alt="Delhaize" width="100%" title="Delhaize Group" />
                <img src={albertsons} alt="Albertsons" width="100%" title="Albertsons" />
                <img src={costco} alt="Costco" width="100%" title="Costco" />
                <img src={ahold} alt="Ahold" width="100%" title="Ahold" />
                <img src={walmart} alt="Walmart" width="100%" title="Walmart" />
                <img src={usda} alt="USDA" width="100%" title="USDA" />
                <img src={wholefoods} alt="Whole Foods" width="100%" title="Whole Foods" />
                <img src={albertsons2} alt="Albertsons" width="100%" title="Albertsons" />
                <img src={safeway} alt="Safeway" width="100%" title="Safeway" />
                <img src={buylowfoods} alt="Buy Low Foods" width="100%" title="Buy Low Foods" />
                <img src={kroger} alt="Kroger" width="100%" title="Kroger" />
              </Carousel>
            </div>
          </div>
          <div id="hero-b-right">
            <img
              src={bee1}
              alt="Produceplan"
              height={64}
              style={{ position:"absolute", top: -80 }}
            />
            <img
              src={barcodeLg}
              alt="Produceplan"
              style={{ backgroundColor: '#fff', padding: '20px', borderRadius: 20 }}
            />
          </div>
        </div>
      </div>
    </div>  

    <div id="print-and-labelling-wrapper">
      <div className="container" id="print-and-labelling">
        <div className="row">
          <div className="col s12 m3" style={{ position: 'relative' }}>
            <img
              src={strawberryImg}
              alt="Produceplan"
              height={84} 
              style={{ textAlign: 'right', position: 'absolute', right: 10, top: 10 }}
            />&nbsp;
          </div>
          <div className="col s12 m6">
            <h5 style={{ textAlign: 'center', fontWeight: 600, paddingTop: 40 }}>Print and Labeling Capabilities</h5>
          </div>
          <div className="col s12 m3" style={{ position: 'relative' }}>
            <a href={pdf} target="_blank" rel="noopener noreferrer" style={{ color: '#002bff', fontSize: 20, fontWeight: 600 }} download>Download a sample label</a>
            <img
              src={bee2}
              alt="Produceplan"
              height={64}
              style={{ textAlign: 'center', position: 'absolute', top: 60, left: 30 }}
            />&nbsp;
          </div>
        </div>
        <div className="row">
          <div className="col s12 m8">
            <ul style={{ fontSize: 18, fontWeight: 500}}>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; PTI Milestones 7 and FSMA 204 compliant</span>
              </li>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Walmart®, Sam's Club and Whole Foods compliant labeling</span>
              </li>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Supports numerous label sizes and formats</span>
              </li>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Remembers label fields and commodity info:</span>
              </li>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Commodity name</span>
              </li>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Commodity GTIN number</span>
              </li>
            </ul>
          </div>
          <div className="col s12 m4">
            <ul style={{ fontSize: 18, fontWeight: 500}}>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Sources</span>
              </li>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Destination/Buyers</span>
              </li>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Packaging date</span>
              </li>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Lot number options</span>
              </li>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Package (item, case, carton, pallet)</span>
              </li>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Single unit or split weight labeling</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m3">
            &nbsp;
          </div>
          <div className="col s12 m6">
            <h5 style={{ textAlign: 'center', fontWeight: 600 }}>Production Reporting</h5>
          </div>
          <div className="col s12 m3">
            &nbsp;
          </div>
        </div>
        <div className="row">
          <div className="col s12 m8">
            <ul style={{ fontSize: 18, fontWeight: 500}}>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Daily summary (by facility and by process)</span>
              </li>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; By commodity or product</span>
              </li>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; By source  DOWN TO THE PLANTING BLOCK LEVEL</span>
              </li>
            </ul>
          </div>
          <div className="col s12 m4">
            <ul style={{ fontSize: 18, fontWeight: 500}}>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Product to destination/Buyer</span>
              </li>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Product trace and trace-back TRACE RECALL</span>
              </li>
              <li>
                <img src={checkmarkImg} alt="Produceplan" height={12} />
                <span>&nbsp; Plus much more!</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div id="for-produce-traceability">
      <div className="container">
        <div className="row" style={{ marginBottom: 0 }}>
          <div className="col s12 m4">
            <h5 style={{ fontWeight: 600 }}>For Produce Traceability</h5>
            <p style={{ fontSize: 17 }}>
              WHETHER YOU NEED TO REPACK OR RELABEL AS A BROKER OR YOU'RE A GROWER/SHIPPER/PACKER WHO SHIPS DOZENS OF LOADS PER DAY, WE HAVE YOU COVERED!
            </p>
            <p style={{ fontSize: 17 }}>
              TRACEABILITY STARTS WITH A PLAN! OUR PTI COMPLIANT INVENTORY SYSTEM COMBINED WITH OUR SUPPLY AND DEMAND PLANNING ALLOW YOU TO EASILY PLAN YEARS IN ADVANCE LIKE NEVER BEFORE...YOUR PLANTING SCHEDULE IS YOUR FIRST STEP TOWARDS PRODUCE TRACEABILITY MADE A LOT EASIER, APPLES TO ZUCCHINI...
            </p>
            <h5 style={{ fontWeight: 600 }}>Success Starts Here!</h5>
          </div>
          <div className="col s12 m8">
            <div  style={{ position: 'relative', width: '100%' }}>
              <div style={{ width: '40%', opacity: 0 }}>
                <img
                  src={barcodeStacked}
                  alt="Produceplan"
                />
              </div>
              <img
                src={barcodeStacked}
                alt="Produceplan"
                style={{ position: 'absolute', top: -60, width: '40%' }}
              />
              <img
                src={barcodeColors}
                alt="Produceplan"
                style={{ position: 'absolute', bottom: 89, left: '40%', width: '60%' }}
              />
              &nbsp;
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m12" style={{ textAlign: 'center' }}>
            <a
              href={demoUrl}
              className="btn btn-green btn-shadowless btn-rounded green"
              target="_blank"
              rel="noopener noreferrer"
            >
              Schedule Demo
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default TraceabilityPage
